import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {useCallback, useEffect, useState} from "react";
import {useRouter} from "next/router";
import LoginCard from "../organisms/LoginCard";
import * as API from "../utils/api";
export default function Home(props: any) {
    const router = useRouter();

    const [loaded, setLoaded] = useState(false)
    useEffect(()=>{
        if (typeof window !== 'undefined') {
            props.getBrowserAgentHandler()?.noticeError('hoge')
        }
        API.post('/hello', {}, {}, false).then((data) => {
            if (!!data && data.success) {
                router.push('/dashboard')
            } else {
                setLoaded(true)
            }
        })
    }, [setLoaded])

    const handleLogin = useCallback(() => {
        props.getBrowserAgentHandler()?.addPageAction('hoge', {'hoge': 'fuga'})
        router.push('/dashboard')
    }, [router, props.getBrowserAgentHandler])

  return (
      <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
          {loaded && (
          <Grid container spacing={3}>
              <Grid item xs={12} onClick={()=>handleLogin()}>
                  気軽に学べるNew Relicの学習サイト
              </Grid>
              <Grid item xs={12}>
                  <LoginCard onLogin={()=>handleLogin()} />
              </Grid>
          </Grid>
          )}
      </Container>
  )
}
